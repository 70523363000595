import React from 'react'
import { Layout } from '../../components/AppLayout'
import { AppCorporateBanner } from '../../components/AppCorporateBanner'
import { AppCorporateWithNav } from '../../components/AppCorporateWithNav'
import { RDHelmet } from '../../components/RDHelmet'

const CorporateSpendVisibilityAndTheComplexitiesOfManagingSpend = () => {
  const schemaMarkup = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Raindrop Systems Inc',
    alternateName: 'Raindrop Systems Inc',
    url: 'https://raindrop.com/',
    logo: 'https://storage.googleapis.com/raindroppublic/website_data/raindrop_icon.svg',
    description:
      'Raindrop is the modern AI-Powered Source-to-Pay SaaS Enterprise Spend Management Full-Suite Modular Platform. Raindrop offers an AI-enabled enterprise cloud based spend management software focused on cost savings. Get started with free tools, and upgrade as you grow.',
    image: 'https://storage.googleapis.com/raindroppublic/website_data/OurServices.jpg',
    email: 'info@raindrop.com',
    contactPoint: [
      { '@type': 'ContactPoint', telephone: '408-352-5162', contactType: 'Business', areaServed: 'United States' },
    ],
    address: {
      '@type': 'Corporate',
      addressLocality: ' 226 Airport Parkway Suite 250',
      addressRegion: ' San Jose, California 95110',
      postalCode: '95110',
      addressCountry: 'United States',
    },
    sameAs: [
      'https://www.facebook.com/Raindrop-Systems-Inc-103810868375793',
      'https://twitter.com/saas_raindrop',
      'https://www.instagram.com/raindrop_spendmanagement/?igshid=1mn6sbd70aybs',
      'https://www.linkedin.com/company/raindropsystemsinc/',
    ],
  }
  return (
    <Layout>
      <RDHelmet
        subtitle={'Spend Management Technology for Managing Spend Complexities | Raindrop Systems Inc.'}
        contentDescription="Insights into the importance of corporate spend visibility & managing spend complexities. Know more at Raindrop: https://raindrop.com/signup"
        contentKeywords="content='Spend management technology solutions, CPOs challenges, 2021 chief procurement officer survey, spend management solution digital transformation, spend management in procurement, emerging technologies in procurement, procurement industry practitioners, source-to-pay technologies, collaborative & secure sourcing solution"
        contentOG="Spend Management Technology for Managing Spend Complexities | Raindrop Systems Inc."
        contentOgDescription="Insights into the importance of corporate spend visibility & managing spend complexities. Know more at Raindrop: https://raindrop.com/signup"
        contentOgUrl="https://raindrop.com/resources/CorporateSpendVisibilityAndTheComplexitiesOfManagingSpend"
        schemaMarkup={schemaMarkup}
      />
      <AppCorporateBanner />
      <AppCorporateWithNav />
    </Layout>
  )
}

export default CorporateSpendVisibilityAndTheComplexitiesOfManagingSpend
